import React from "react";
import "./nav.css";

import logo2 from "../../img/logo2.png";
import { Link } from "react-scroll/modules";

import { useNavigate } from 'react-router-dom';


export default function Nav2() {

  const navigate = useNavigate();
  const handleNavigate = (page) => () => {
    navigate(`../?p=${page}`);
  };
  return (
    <>
      {/* navigation 1 */}
      <nav className="nav-1">
        <div className="nav-1-content">
          {/* navigation one content 1 */}
          <div className="nav-1-c-1">
            <ul>
              <li>
                <p>
                  <i className="ri-road-map-fill"></i> No: 96/4A, Boralasgamuwa,
                  Colombo
                </p>
              </li>
              <li>
                <p>
                  <i className="ri-phone-fill"></i>
                  <a href="tel:+94112795603"> +94 112 795 603</a>
                </p>
              </li>
              <li>
                <p>
                  <i className="ri-mail-line"></i>{" "}
                  <a href="mailto:support@fuchsius.com">support@fuchsius.com</a>
                </p>
              </li>
            </ul>
          </div>
          {/* navigation one content 2 */}
          <div className="nav-1-c-2">
            <ul>
              <li>Follow Us On :</li>
              <li>
                <a href="">
                  <i className="ri-facebook-fill"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="ri-twitter-x-fill"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="ri-linkedin-fill"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="ri-instagram-line"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="ri-youtube-fill"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* navigation gradient */}
        <div className="nav-1-style"></div>
      </nav>
      {/* navigation 2  */}
      <nav className="nav-2">
        <div className="nav-2-content">
          <div className="nav-c-1">
            <div className="nav-c1-logo">
              <a onClick={handleNavigate('home')}>
                <img style={{ cursor: "pointer" }} src={logo2} alt="" />
              </a>
            </div>
            <div className="nav-c1-style"></div>
          </div>
          <div className="nav-c-2">
            <ul>
              <li>
                <a onClick={handleNavigate('home')}>
                  Home
                </a>
              </li>
              <li>
                <a onClick={handleNavigate('about-us')}>
                  About Us
                </a>
              </li>
              <li>
                <a onClick={handleNavigate('service')}>
                  Services
                </a>
              </li>
              {/* <li>
                                <a href="">Blog</a>
                            </li> */}
              <li>
                <a onClick={handleNavigate('contact')}>
                  Contact
                </a>
              </li>
            </ul>
            <div className="nav-c2-style"></div>
          </div>
          <div className="nav-c-3">
            <a href="tel:+94112795603">
              <div className="nav-button">Call now</div>
              <div className="nav-button-ani"></div>
            </a>
            <div className="nav-c3-style"></div>
          </div>
        </div>
      </nav >
    </>
  );
}
