import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Button from '../components/common/button/Button1.js';
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"

import Qa from "../components/service/Qa.js";
export default function Contact() {

  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='UI/UX Design'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>UI/UX Design</h2>
            <div className="service-detail-p">
              <p>
              Craft intuitive user experiences. Design visually appealing and user-friendly interfaces.
              </p>
              <br />
              <p>
                We employ user-centered design principles to ensure that your digital products are both functional and aesthetically pleasing. From wireframes to final designs, our approach prioritizes seamless interactions and satisfying user journeys, leading to increased satisfaction and retention.
              </p>
            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                  <h4>Customer Engagement</h4>
                  <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="Where can I get analytics help?"
                description="
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum nostrum quis praesentium iste tempore temporibus enim voluptatibus deserunt nesciunt quae, nemo repudiandae neque ratione aperiam officiis laboriosam dolor excepturi repellendus!
              "
              ></Qa>
              <Qa
                title="How long should a business plan be?"
                description="
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum nostrum quis praesentium iste tempore temporibus enim voluptatibus deserunt nesciunt quae, nemo repudiandae neque ratione aperiam officiis laboriosam dolor excepturi repellendus!
              "
              ></Qa>
              <Qa
                title="How To Choose A Good QA Consultant?"
                description="
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum nostrum quis praesentium iste tempore temporibus enim voluptatibus deserunt nesciunt quae, nemo repudiandae neque ratione aperiam officiis laboriosam dolor excepturi repellendus!
              "
              ></Qa>
              <Qa
                title="How To Choose A Good QA Consultant?"
                description="
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum nostrum quis praesentium iste tempore temporibus enim voluptatibus deserunt nesciunt quae, nemo repudiandae neque ratione aperiam officiis laboriosam dolor excepturi repellendus!
              "
              ></Qa>
            </div>

          </div>
          <div className="service-detail-c1-c2">
          <ServiceList></ServiceList>
          <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
