import React from 'react'
import Button from '../common/button/Button1'
import Image from "../../img/theme-img/qb.jpg";

export default function ServiceContact() {
  return (
    <>
      <div className="service-contact">
        <div className="service-contact-c">
          <h5>CONTACT US NOW</h5>
          <h1>You Need Help?</h1>
          <div className="service-contact-button">
            <Button text='Contact Us'></Button>
          </div>
        </div>
        <div className="service-contact-bg">
          <img src={Image} alt="" />
        </div>
      </div>
    </>
  )
}
