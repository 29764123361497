import React from "react";
import "./nav.css";

import logo2 from "../../img/logo2.png";
import { Link } from "react-scroll/modules";

export default function nav() {
  return (
    <>
      {/* navigation 1 */}
      <nav className="nav-1">
        <div className="nav-1-content">
          {/* navigation one content 1 */}
          <div className="nav-1-c-1">
            <ul>
              <li>
                <p>
                  <i className="ri-road-map-fill"></i> 14 Sir Baron Jayathilake Mawatha, Colombo 01
                </p>
              </li>
              <li>
                <p>
                  <i className="ri-phone-fill"></i>
                  <a href="tel:+94112795603"> +94 112 795 603</a>
                </p>
              </li>
              <li>
                <p>
                  <i className="ri-mail-line"></i>{" "}
                  <a href="mailto:support@fuchsius.com">support@fuchsius.com</a>
                </p>
              </li>
            </ul>
          </div>
          {/* navigation one content 2 */}
          <div className="nav-1-c-2">
            <ul>
              <li>Follow Us On :</li>
              <li>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61556400211892">
                  <i className="ri-facebook-fill"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://x.com/?lang=en&mx=2">
                  <i className="ri-twitter-x-fill"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://lk.linkedin.com/company/fuchsius">
                  <i className="ri-linkedin-fill"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/fuchsius_official/">
                  <i className="ri-instagram-line"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.youtube.com/">
                  <i className="ri-youtube-fill"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* navigation gradient */}
        <div className="nav-1-style"></div>
      </nav>
      {/* navigation 2  */}
      <nav className="nav-2">
        <div className="nav-2-content">
          <div className="nav-c-1">

            <div className="nav-c1-logo" >
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-230}
                duration={100}
              >
                <img style={{ cursor: "pointer" }} src={logo2} alt="" />
              </Link>
            </div>

            <div className="nav-c1-style"></div>
          </div>
          <div className="nav-c-2">
            <ul>
              <li>
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-230}
                  duration={100}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="about-us"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={100}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  activeClass="active"
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-130}
                  duration={100}
                >
                  Services
                </Link>
              </li>
              {/* <li>
                                <a href="">Blog</a>
                            </li> */}
              <li>
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={100}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <div className="nav-c2-style"></div>
          </div>
          <div className="nav-c-3">
            <a href="tel:+94112795603">
              <div className="nav-button">Call now</div>
              <div className="nav-button-ani"></div>
            </a>
            <div className="nav-c3-style"></div>
          </div>
        </div>
      </nav>
    </>
  );
}
