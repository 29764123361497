import React, { useState, useEffect } from 'react';

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import items from "../components/home/ServicesList1.js";
import Title from "../components/common/title/Title.js";
import Button1 from "../components/common/button/Button1.js";
import TeamList from "../components/home/TeamList.js";
import Review from "../components/home/Review.js";
import Nav from "../components/nav/Nav.js";
import Footer from "../components/footer/Footer.js";


import ServicesList2 from "../components/home/ServicesList2.js";

import userProfile from "../img/user.png";
import image1 from "../img/1.jpg";
import imageBgLine from "../img/bg/line-1.png";
import imageTest from "../img/1.jpg";
import image2 from "../img/2.png";
import certificateIcon from "../img/team/certificate.png"
import teamwork from "../img/team/teamwork.png"


import appDevelopment from "../img/service/app-development.png"
import digitalMarketing from "../img/service/digital-marketing.png"
import seo from "../img/service/seo.png"
import technicalSupport from "../img/service/technical-support.png"
import uxDesign from "../img/service/ux-design.png"
import webDevelopement from "../img/service/web-developement.png"

import "../assets/style/home.css";
import { Link as ScrollLink } from 'react-scroll/modules';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';


export default function Home() {
  const options = [
    { value: "1", label: "Consultation" },
    { value: "2", label: "Project Discussion" },
    { value: "3", label: "Technical Support" },
    { value: "3", label: "Follow-Up Meeting" },
    { value: "3", label: "Career Opportunities" },

  ];


  const [activeSlide, setActiveSlide] = useState(0);
  const slides = ['slide1', 'slide2', 'slide3', 'slide4', 'slide5']; 

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const section = params.get('p');

    if (section) {
      scroller.scrollTo(section, {
        smooth: true,
        offset: -220,
        duration: 100,
      });
    }
  }, [search]); // Runs when `search` changes

  // Effect for managing slide interval
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(prev => (prev + 1) % slides.length);
    }, 5000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [slides.length]); 

  return (
    <>
      <Nav></Nav>
      <section className="slide" id="home">
        {/* slide list */}
        <div className="slide-l">
          <div className="slide-i">
            <img src={image1} alt="" />
          </div>
          {/* slide content */}

          <div className="slide-c active">
            <div className="slide-cc container">
              <div
                className={`slide-list ${activeSlide === 0 ? 'active' : ''}`}
                id="slide1"
              >
                <h4>Technology Transformation</h4>
                <h1>Technology into Business Success</h1>
                <p>
                  Leverage advanced technology to drive business growth and
                  efficiency, streamline processes, enhance productivity, reduce
                  costs, and achieve greater profitability and success in
                  today's competitive market. Empower your business with
                  innovative solutions.
                </p>
                <div className="slide-cc-buttons">
                <ScrollLink
                    activeClass="active"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >

                      <div className="slide-cc-button">
                       DISCOVER MORE&nbsp;<i class="ri-arrow-right-line"></i>
                      </div>
                      <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                  <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                    <div className="slide-cc-button">
                      CONTACT US&nbsp;<i class="ri-arrow-right-line"></i>
                    </div>
                    <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                </div>
              </div>

            </div>
          </div>
          <div className="slide-c active">
            <div className="slide-cc container">
              <div
                className={`slide-list ${activeSlide === 1 ? 'active' : ''}`}
                id="slide1"
              >
                <h4>Digital Transformation Solutions</h4>
                <h1>Accelerate Your Business Growth</h1>
                <p>Embark on a digital transformation journey with our comprehensive solutions. From custom web application development to mobile app development, we help you optimize operations, enhance customer experiences, and gain a competitive edge. Our tailored approach ensures seamless integration and maximum ROI.</p>
                <div className="slide-cc-buttons">
                <ScrollLink
                    activeClass="active"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >

                      <div className="slide-cc-button">
                      DISCOVER MORE&nbsp;<i class="ri-arrow-right-line"></i>
                      </div>
                      <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                  <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                    <div className="slide-cc-button">
                      CONTACT US&nbsp;<i class="ri-arrow-right-line"></i>
                    </div>
                    <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                </div>
              </div>

            </div>
          </div>
          <div className="slide-c active">
            <div className="slide-cc container">
              <div
                className={`slide-list ${activeSlide === 2 ? 'active' : ''}`}
                id="slide1"
              >
                <h4>Industry Expertise</h4>
                <h1>Proven Results Across Industries</h1>
                <p>With a deep understanding of e-commerce platforms, healthcare information systems, and financial technology applications, we deliver industry-specific solutions that address your unique challenges. Our proven track record of success speaks for itself. Let us help you achieve your business goals.</p>
                <div className="slide-cc-buttons">
                <ScrollLink
                    activeClass="active"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >

                      <div className="slide-cc-button">
                      DISCOVER MORE&nbsp;<i class="ri-arrow-right-line"></i>
                      </div>
                      <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                  <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                    <div className="slide-cc-button">
                      CONTACT US&nbsp;<i class="ri-arrow-right-line"></i>
                    </div>
                    <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-c active">
            <div className="slide-cc container">
              <div
                className={`slide-list ${activeSlide === 3 ? 'active' : ''}`}
                id="slide1"
              >
                <h4>Client Success Stories</h4>
                <h1>Partnering for Growth</h1>
                <p>Discover how our technology solutions have transformed businesses across industries. From e-commerce retailers to healthcare providers, our clients have achieved remarkable results. Read their success stories and learn how we can help you achieve your goals.</p>
                <div className="slide-cc-buttons">
                <ScrollLink
                    activeClass="active"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                      <div className="slide-cc-button">
                      DISCOVER MORE&nbsp;<i class="ri-arrow-right-line"></i>
                      </div>
                      <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                  <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                    <div className="slide-cc-button">
                      CONTACT US&nbsp;<i class="ri-arrow-right-line"></i>
                    </div>
                    <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-c active">
            <div className="slide-cc container">
              <div
                className={`slide-list ${activeSlide === 4 ? 'active' : ''}`}
                id="slide1"
              >
                <h4>Cutting-Edge Technology</h4>
                <h1>Innovation at Your Service</h1>
                <p>Stay ahead of the curve with our expertise in React Native, Python/Django, and AWS. We leverage the latest advancements to deliver scalable and robust solutions that drive business efficiency and innovation.</p>
                <div className="slide-cc-buttons">
                  <ScrollLink
                    activeClass="active"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                      <div className="slide-cc-button">
                      DISCOVER MORE&nbsp;<i class="ri-arrow-right-line"></i>
                      </div>
                      <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                  <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={100}
                  >
                    <div className="slide-cc-button">
                      CONTACT US&nbsp;<i class="ri-arrow-right-line"></i>
                    </div>
                    <div className="slide-cc-button-ani"></div>
                  </ScrollLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* animation one */}
        <div className="slide-ani-1">
          <span className="line-1"></span>
          <span className="line-2"></span>
          <span className="line-3"></span>
          <span className="line-4"></span>
          <span className="line-5"></span>
          <span className="line-6"></span>
        </div>
        <div className="slide-bg-lines">
          <img src={imageBgLine} alt="" />
          <img src={imageBgLine} alt="" />
        </div>
      </section>

      <section className="home-c1">
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={{
            0: { items: 1 },
            768: { items: 2 },
            1024: { items: 3 },
          }}
          autoPlay
          autoPlayInterval={2000}
          disableButtonsControls
          disableDotsControls
          infinite
        />
      </section>

      <section className="home-c2 container " id="about-us">
        <div className="home-c2-c1">
          <img src={image2} alt="" />
          <div className="home-c2-c1-experience">
            <div className="home-c2-c1-experience-c">
              <h2>02</h2>
              <p>Years Experience</p>
            </div>
            <div className="home-c2-c1-experience-ani"></div>
          </div>
        </div>
        <div className="home-c2-c2">
          <div className="home-c2-c2-b">
            <Title text={"ABOUT US"}></Title>
            <h1 className="mt40">
              We Are Increasing Business Success With <span>IT Solution</span>{" "}
            </h1>
            <p className="mt40">
              We are a leading IT solutions provider offering web and mobile development, digital marketing, cloud services, and IT support. Our expertise helps businesses thrive in the digital age.
            </p>
            <div className="home-c2c2-cd">
              <div className="home-c2c2-cd-1">
                <div className="home-c2c2-cd-image">
                  <img src={certificateIcon} alt="" />
                </div>
                <div className="home-c2c2-cd-details">
                  <h3>Certified Company</h3>
                  <p>Best Provide Skills Services</p>
                </div>
              </div>
              <div className="home-c2c2-cd-2">
                <div className="home-c2c2-cd-image">
                  <img src={teamwork} alt="" />
                </div>
                <div className="home-c2c2-cd-details">
                  <h3>Expert Team</h3>
                  <p>100% Expert Team</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="home-c2-c2-button">
              <div className="home-c2-c2-button-c1">
                <Button1 text="DISCOVER MORE"></Button1>
              </div>
              <div className="home-c2-c2-button-c2">
                <a href="tel:+94112795603">
                  <div className="home-c2c2-cd-button-icon">
                    <i class="ri-phone-fill"></i>
                  </div>
                  <div className="home-c2c2-cd-button-details">
                    <div>
                      <span>Call Us On:</span>
                      <p>+94 112 795 603</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-c3 " id="service">
        <div className="container">
          <div className="home-c3-header">
            <div className="home-c3-header-title">
              <Title text={"OUR SERVICES"}></Title>
            </div>
            <div className="home-c3-header-title-d">
              <h1>
                We Provide Exclusive <span>Service </span>For Your Business
              </h1>
            </div>
          </div>
          <div className="home-c3-content">
            <div className="home-c3-grid-list">
              <ServicesList2
                title="Web Development"
                icon="bi bi-code-slash"
                description="Create stunning, functional websites tailored to your brand. Build custom web applications for streamlined operations. Power online stores to boost sales and customer engagement."
                number="01"
                path="/service-web-develpment"
              ></ServicesList2>
              <ServicesList2
                title="Mobile App Development"
                icon="bi bi-phone"
                description="Create engaging mobile apps tailored to your audience. Build native apps for iOS and Android platforms to maximize reach and performance."
                number="02"
                path="/service-mobile-app-development"
              ></ServicesList2>
              <ServicesList2
                title="UI/UX Design"
                icon="bi bi-palette"
                description="Craft intuitive user experiences. Design visually appealing and user-friendly interfaces."
                number="03"
                path="/service-ui-ux"
              ></ServicesList2>
              <ServicesList2
                title="Digital Marketing"
                icon="bi bi-rocket-takeoff"
                description="Elevate your online presence with our comprehensive digital marketing strategies. Boost search engine rankings and drive targeted traffic to maximize your business growth."
                number="04"
                path="/service-digital-marketing"
              ></ServicesList2>
              <ServicesList2
                title="IT Support"
                icon="bi bi-headset"
                description="Ensure seamless operations with our expert IT support. Protect your business from cyber threats with robust security measures."
                number="05"
                path="/service-it-Support"
              ></ServicesList2>
              <ServicesList2
                title="SEO"
                icon="bi bi-graph-up-arrow"
                description="Enhance your website's visibility on search engines with our expert SEO services. Improve organic search rankings and attract high-quality traffic to increase your business's online presence and growth."
                number="06"
                path="/service-seo"
              ></ServicesList2>
            </div>
          </div>
        </div>
      </section>

      <section className="home-c4 container" id="our-team">
        <div className="home-c4-c1">
          <Title text={"WHY CHOOSE US"}></Title>
          <h1>
            We Deal With The Aspects Professional <span>IT Services</span>
          </h1>
          <p>
            We design efficient, user-friendly supply chains that optimize cross-functional collaboration. Our team builds strong, competitive resources to achieve overall business success.
          </p>
          <div className="home-c4-content">
            <div className="home-c4-c-c1">
              <div className="home-c4-c-details">
                <div className="home-c4-c-icon">
                  <i class="ri-check-line"></i>
                </div>
                <h3>Big Data Analysis</h3>
              </div>
              <div className="home-c4-c-details">
                <div className="home-c4-c-icon">
                  <i class="ri-check-line"></i>
                </div>
                <h3>24/7 Online Support</h3>
              </div>
              <div className="home-c4-c-details">
                <div className="home-c4-c-icon">
                  <i class="ri-check-line"></i>
                </div>
                <h3>Business Improvement</h3>
              </div>
            </div>
            <div className="home-c4-c-c2">
              <div className="home-c4-c-details">
                <div className="home-c4-c-icon">
                  <i class="ri-check-line"></i>
                </div>
                <h3>High Quality Security</h3>
              </div>
              <div className="home-c4-c-details">
                <div className="home-c4-c-icon">
                  <i class="ri-check-line"></i>
                </div>
                <h3>24/7 Support Team</h3>
              </div>
              <div className="home-c4-c-details">
                <div className="home-c4-c-icon">
                  <i class="ri-check-line"></i>
                </div>
                <h3>Easy Solutions</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="home-c4-c2">
          <div className="home-c4-animation-1"></div>
          <div className="home-c4-animation-2"></div>
          <div className="home-c4-animation-3"></div>
        </div>
      </section>

      <section className="home-c5 container" id="page5">
        <div className="home-c5-content-1">
          <div className="home-c5-c1 home-c5-c">
            <div className="home-c5-d1">
              <div className="home-c5-icon">
                <i className="bi-person-check"></i>
              </div>
            </div>
            <div className="home-c5-d2">
              <h2>2+</h2>
              <p>Years of Experience</p>
            </div>
          </div>
          <div className="home-c5-c2 home-c5-c">
            <div className="home-c5-d1">
              <div className="home-c5-icon">
              <i class="bi bi-journal-check"></i>
              </div>
            </div>
            <div className="home-c5-d2">
              <h2>20+</h2>
              <p>Projects completed</p>
            </div>
          </div>
        </div>
        <div className="home-c5-content-2">
          <div className="home-c5-c3 home-c5-c">
            <div className="home-c5-d1">
              <div className="home-c5-icon">
              <i class="bi bi-people-fill"></i>
              </div>
            </div>
            <div className="home-c5-d2">
              <h2>10+</h2>
              <p>Team Members</p>
            </div>
          </div>
          <div className="home-c5-c4 home-c5-c">
            <div className="home-c5-d1">
              <div className="home-c5-icon">
              <i class="bi bi-emoji-laughing"></i>
              </div>
            </div>
            <div className="home-c5-d2">
              <h2>30+</h2>
              <p>Satisfied Clients</p>
            </div>
          </div>
        </div>

        <div className="home-c5-animation">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>

      <section className="home-c6" id="page6">
        <div className="home-c6-header">
          <div className="home-c6-title">
            <Title text=" TEAM MEMBER"></Title>
          </div>
          <h1>
            See Our Skilled <span>Expert Team</span>
          </h1>
        </div>
        <div className="home-c6-content container">
          <AliceCarousel
            mouseTracking
            items={TeamList}
            responsive={{
              0: { items: 1 },
              576: { items: 2 },
              992: { items: 3 },
              1200: { items: 4 },
            }}
            autoPlay
            autoPlayInterval={2000}
            disableButtonsControls
            disableDotsControls
            infinite
            stagePadding={{ paddingLeft: 20, paddingRight: 20 }}
          />
        </div>
        <div className="home-c6-background">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>

      {/* <section className="home-c7" id="page7">
        <div className="home-c7-c container">
          <div className="home-c7-c-1">
            <div className="home-c7-title">
              <Title text="TESTIMONIALS"></Title>
            </div>
            <h1>
              Let’s Here What Are The Customer <span>Saying</span>
            </h1>
            <p>
              Your voice matters! Share your experiences, feedback, and suggestions to help us improve.
            </p>
            <div className="home-c7-button">
              <Button1
                text="DISCOVER MORE"
                icon="ri-arrow-right-line"
              ></Button1>
            </div>
          </div>
          <div className="home-c7-c-2">
            <AliceCarousel
              mouseTracking
              items={Review}
              responsive={{
                0: { items: 1 },
              }}
              autoPlay
              autoPlayInterval={2000}
              disableButtonsControls
              disableDotsControls
              infinite
              stagePadding={{ paddingLeft: 20, paddingRight: 20 }}
            />
          </div>
        </div>
      </section> */}

      <section className="home-c8" id="contact">
        <div className="home-c8-c container">
          <div className="home-c8-c1">
            <div className="home-c8-header">
              <div className="home-c8-title">
                <Title text="Contact Us"></Title>
              </div>
              <h1>
                We're Ready To Talk About Your <span>Opportunities</span>
              </h1>
              <p>
                Develop core online marketplaces focused on customer needs. Nurture online retailers to become industry leaders. Expand cutting-edge platforms for round-the-clock commerce. Continuously improve system capabilities based on customer feedback.
              </p>
              <div className="home-c8-content">
                <div className="home-c8-buttons">
                  <div className="home-c8-button-1">
                    <div className="home-c8-button-c1">
                      <div className="home-c8-button-icon">
                        <i class="ri-phone-fill"></i>
                      </div>
                    </div>
                    <div className="home-c8-button-c2">
                      <h3>Call Us On:</h3>
                      <a href="tel:+94112795603"> +94 112 795 603</a>
                    </div>
                  </div>
                  <div className="home-c8-button-2">
                    <div className="home-c8-button-c1">
                      <div className="home-c8-button-icon">
                        <i class="ri-mail-line"></i>
                      </div>
                    </div>
                    <div className="home-c8-button-c2">
                      <h3>Quick Mail Us:</h3>
                      <a href="mailto:support@fuchsius.com">support@fuchsius.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-c8-c2">
            <div className="home-c8-c2-header">
              <h2>Make An Appointment</h2>
              <div className="home-c8-c2-input">
                <div className="inputs">
                  <div className="input-two-line mt20">
                    <input type="text" placeholder="Enter Your Name" />
                    <input type="text" placeholder="Enter Your Email" />
                  </div>
                  <div className="input-two-line mt20">
                    <select name="" id="">
                      {options.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          style={{
                            color: "rgba(0, 0, 0, 0.4)",
                            height: "30px",
                          }}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <input type="text" placeholder="Phone Number" />
                  </div>
                  <div className="input-one-line mt20">
                    {/* <input type="text" placeholder='' /> */}
                    <textarea
                      name=""
                      id=""
                      placeholder="Write Your Message"
                    ></textarea>
                  </div>
                  <div className="home-c8-button mt20">
                    <Button1 text="Submit"></Button1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}
